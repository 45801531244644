<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="mb-4"> Contractor Balance Sheet </v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-row>
                <!-- <v-col cols="12" md="4">
                  <v-menu :close-on-content-click="false" transition="scale-transition" offset-y eager min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Date Range"
                        v-model="dates"
                        class="header-inputs flex-grow-0"
                        readonly
                        outlined
                        dense
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>

                    <v-date-picker v-on:input="getCustomers" v-model="dates" range></v-date-picker>
                  </v-menu>
                </v-col> -->
                <v-col cols="12" md="4">
                  <v-menu :close-on-content-click="false" transition="scale-transition" offset-y eager min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Start Date"
                        v-model="start_date"
                        class="header-inputs flex-grow-0"
                        readonly
                        outlined
                        dense
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="start_date" v-on:input="getCustomers"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="4">
                  <v-menu :close-on-content-click="false" transition="scale-transition" offset-y eager min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="End Date"
                        v-model="end_date"
                        class="header-inputs flex-grow-0"
                        readonly
                        outlined
                        dense
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="end_date" v-on:input="getCustomers"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select
                    v-model="selected_units"
                    :items="units"
                    label="Units(s)"
                    item-text="name"
                    required
                    hide-details="auto"
                    class="mb-6"
                    outlined
                    dense
                    multiple
                    v-on:input="getCustomers"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    v-model="selected_customer"
                    :items="customers"
                    item-text="customer"
                    item-value="customerID"
                    label="Customer"
                    required
                    hide-details="auto"
                    class="mb-6"
                    outlined
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    v-model="selected_job_type"
                    :items="job_types"
                    label="Job Type"
                    item-text="name"
                    required
                    hide-details="auto"
                    class="mb-6"
                    outlined
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    v-model="selected_operation"
                    :items="operations"
                    label="Operations"
                    item-text="name"
                    required
                    hide-details="auto"
                    class="mb-6"
                    outlined
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="6" class="pt-0">
                  <v-btn class="mr-3" color="primary" @click="getCBSData" :disabled="submit_disabled || submitLoading">
                    <template v-if="submitLoading">
                      <v-progress-circular :size="22" :width="2" indeterminate color="white"></v-progress-circular>
                    </template>
                    <template v-else> Submit </template>
                  </v-btn>
                  <export-to-excel
                    class="mr-3"
                    dataSource="cbs"
                    exportType="xlsx"
                    :dataToExport="formattedTicketList"
                    :totals="totals"
                    :startDate="start_date"
                    :endDate="end_date"
                    :units="selected_units"
                    :exportButtonDisabled="!isReportVisible"
                  />
                  <export-to-excel
                    dataSource="cbs"
                    exportType="csv"
                    :dataToExport="formattedTicketList"
                    :totals="totals"
                    :startDate="start_date"
                    :endDate="end_date"
                    :units="selected_units"
                    :exportButtonDisabled="!isReportVisible"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-switch
                    v-if="isReportVisible"
                    v-model="showApprovedTickets"
                    inset
                    class="mt-0"
                    :label="`Show approved tickets`"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- <CBSData :isReportVisible="isReportVisible" :ticketList="ticketList"></CBSData> -->
    <CBSDatav2
      :isReportVisible="isReportVisible"
      :showApprovedTickets="showApprovedTickets"
      :ticketList="formattedTicketList"
    ></CBSDatav2>

    <!-- <CBSTotals v-if="isReportVisible" :ticketList="ticketList"> </CBSTotals> -->
    <CBSTotalsv2
      @set-totals-calculations="setTotalsCalculations"
      v-if="isReportVisible"
      :ticketList="formattedTicketList"
    >
    </CBSTotalsv2>
  </div>
</template>

<script>
import { mdiDotsVertical, mdiSquareEditOutline } from '@mdi/js'
import firebase from 'firebase'
import CBSDatav2 from '../components/CBSDatav2.vue'
import CBSTotalsv2 from '../components/CBSTotalsv2.vue'
import ExportToExcel from '../components/ExportData.vue'
// import { generateTicketList } from '../components/GenerateTicketLlist.js'
import { generateTicketList } from '../components/GenerateTicketListV2.js'

const axios = require('axios').default

export default {
  page: {
    name: 'Contractor Balance Sheet',
  },
  components: {
    CBSTotalsv2,
    CBSDatav2,
    ExportToExcel,
  },
  data() {
    return {
      ticketList: [],
      formattedTicketList: [],
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
      start_date: null,
      end_date: null,
      // dates: null,
      selected_units: null,
      selected_customer: null,
      selected_job_type: null,
      selected_operation: null,
      submit_disabled: true,
      operations: [
        { name: 'All Locations', value: ' ' },
        { name: 'Hinton', value: 'Hinton' },
        { name: 'Rocky Mountain House', value: 'Rocky Mountain House' },
      ],
      job_types: [
        { name: 'All Job Types', value: 'Yes,No,N12,D15,T0,L12' },
        { name: 'Production 12%', value: 'Yes' },
        { name: 'Service 15%', value: 'No' },
        { name: 'Services 12%', value: 'N12' },
        { name: 'Drilling 15%', value: 'D15' },
        { name: 'LPG 12%', value: 'L12' },
        { name: 'Travel 0%', value: 'T0' },
        { name: 'Drilling/Services 15%', value: 'D15,No' },
      ],
      customers: [{ customer: 'All customers', customerID: 0 }],
      units: [],
      isReportVisible: false,
      totals: {},
      showApprovedTickets: true,
      submitLoading: false,
    }
  },
  methods: {
    setTotalsCalculations(totals) {
      this.totals = totals
    },
    getCustomers: function () {
      try {
        if (this.validateGetCustomers()) {
          //get a list of customers on page load.
          axios
            .post(
              // 'https://localhost:44370/api/Customers/contractor-specific-customers',
              'https://wilardapi.azurewebsites.net/api/Customers/contractor-specific-customers',
              {
                units: this.selected_units.toString(),
                startDate: this.start_date,
                endDate: this.end_date,
              },
              {
                headers: { ApiKey: process.env.VUE_APP_WILARD_API_KEY },
              },
            )
            .then(response => {
              //filter out all of the customers, so it is only the active customers.
              this.customers = response.data.sort((a, b) => (a.customer < b.customer ? -1 : 1))
              this.customers.unshift({ customer: 'All customers', customerID: 0 })
              //set the all customers option as selected.
              this.selected_customer = 0
            })
            .catch(err => {
              console.log(`Error calling get customers network request: ${err}`)
              this.$toasted.show('Error getting customer list', {
                duration: null,
                keepOnHover: true,
                type: 'error',
              })
            })
        }
      } catch (err) {
        console.log(`Error getting customers: ${err}`)
        this.$toasted.show('Error getting customers', {
          duration: null,
          keepOnHover: true,
          type: 'error',
        })
      }
    },
    getUnits: function () {
      firebase
        .firestore()
        .collection('orgs')
        .doc(sessionStorage.selectedOrg)
        .get()
        .then(doc => {
          if (doc.exists) {
            this.units = doc.data().units ? doc.data().units : []
            this.units.sort()
          }
        })
        .catch(err => {
          console.log(`Error getting units: ${err}`)
          this.$toasted.show('Error getting units', {
            duration: null,
            keepOnHover: true,
            type: 'error',
          })
        })
    },
    getCBSData: function () {
      try {
        this.submitLoading = true
        //clear the previous data.
        this.ticketList = []
        axios
          .post(
            // 'https://localhost:44370/api/Tickets/contractor-balance-sheet',
            'https://wilardapi.azurewebsites.net/api/Tickets/contractor-balance-sheet',
            {
              unitID: this.selected_units.toString(),
              production: this.selected_job_type,
              commision: '0.15',
              dateA: this.start_date,
              dateB: this.end_date,
              customer: this.selected_customer,
              operations: this.selected_operation,
            },
            {
              headers: { ApiKey: process.env.VUE_APP_WILARD_API_KEY },
              // headers: { ApiKey: 'd&EJ5&VaCyyN8wZAs' },
            },
          )
          .then(async response => {
            //filter out all of the customers, so it is only the active customers.
            this.ticketList = response.data
            this.formattedTicketList = await generateTicketList(this.ticketList)
            this.isReportVisible = true
            this.submitLoading = false
          })
          .catch(err => {
            this.submitLoading = false
            console.log(`Error calling CBS network request: ${err}`)
            this.$toasted.show('Error calling CBS network request', {
              duration: null,
              keepOnHover: true,
              type: 'error',
            })
          })
      } catch (err) {
        this.submitLoading = false
        console.log(`Error getting CBS data: ${err}`)
        this.$toasted.show('Error getting CBS data', {
          duration: null,
          keepOnHover: true,
          type: 'error',
        })
      }
    },
    validateGetCustomers: function () {
      this.submit_disabled = true
      //chheck to see if all inputs are valid before getting the list of customers.
      if (this.selected_units == null || this.selected_units.length <= 0) {
        return false
      } else if (this.start_date == null) {
        return false
      } else if (this.end_date == null) {
        return false
      } else {
        this.submit_disabled = false
        return true
      }
    },
  },
  watch: {
    start_date() {
      this.isReportVisible = false
    },
    end_date() {
      this.isReportVisible = false
    },
    selected_units() {
      this.isReportVisible = false
    },
    selected_customer() {
      this.isReportVisible = false
    },
    selected_job_type() {
      this.isReportVisible = false
    },
    selected_operation() {
      this.isReportVisible = false
    },
  },
  mounted() {
    this.getCustomers()
    this.getUnits()

    //set the all job types option as selected.
    this.selected_job_type = 'Yes,No,N12,D15,T0,L12'
    this.selected_operation = ' '
  },
}
</script>
