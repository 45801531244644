<!-- SETUP TO BE PASSED A DATASOURCE WHICH IS THE VIEW THE DATA IS COMING FROM SUCH AS CBS (CONTRACTOR BALANCE SHEET) AND TO BE PASSED AN EXPORT TYPE WHICH IS USED TO GENERATE AND DOWNLOAD XLSX OR CSV  -->
<template>
  <v-btn color="info" @click="exportDataToExcel()" :disabled="exportButtonDisabled">
    {{ exportType === 'xlsx' ? 'Export to Excel' : 'Export to CSV' }}
  </v-btn>
</template>

<script>
// imports
import * as papaParse from 'papaparse'
import * as XLSX from 'xlsx'

export default {
  name: 'ExportToExcel',
  props: {
    dataSource: String,
    exportType: String,
    dataToExport: Array,
    totals: Object,
    startDate: String,
    endDate: String,
    units: Array,
    exportButtonDisabled: Boolean,
  },
  methods: {
    exportDataToExcel() {
      if (this.dataSource) {
        let reportInfoString = ''
        // create new book
        const workbook = XLSX.utils.book_new()
        // create cbs worksheet
        const cbsWorksheet = XLSX.utils.json_to_sheet([{}])
        if (this.dataSource === 'cbs') {
          // Check if there is data to export
          if (this.dataToExport.length > 0) {
            // make report info string
            this.units.map((unit, index) => {
              if (index === 0) {
                reportInfoString = unit
              } else {
                reportInfoString += `, ${unit}`
              }
            })
            let reportInfo = `Report from ${this.startDate} to ${this.endDate} for unit(s): ${reportInfoString}`
            // set the value for the report info range
            XLSX.utils.sheet_add_aoa(cbsWorksheet, [[reportInfo]], { origin: { r: 0, c: 0 } })
            // merge cells for the report info
            cbsWorksheet['!merges'] = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }]
            // manage column order
            let formattedData = this.formatColumns()
            // insert table data at 2 so there is space between the report info
            XLSX.utils.sheet_add_json(cbsWorksheet, formattedData, { origin: 2 })
            // calculate column widths for data headers
            const colWidths = formattedData[0]
              ? Object.keys(formattedData[0]).map(key =>
                  Math.max(key.length, ...formattedData.map(row => String(row[key]).length)),
                )
              : []
            // add new row below table data for the totals
            // calculate the index for the new row (2 rows below the last row of data, factor in two spaces at top)
            const newIndex = formattedData.length + 4
            // make array for the totals title
            const totalsTitle = ['TOTALS']
            // convert the new row data to an array of values
            const totalsRowArray = Object.values(this.totals)
            // modify headers for the new row
            const newHeaders = Object.keys(this.totals).map(key => {
              let modifiedKey = key
                .replace(/([A-Z])/g, ' $1')
                .replace(/\b\w/g, match => match.toUpperCase())
                .trim()
              this.totals[modifiedKey] = this.totals[key]
              delete this.totals[key]
              return modifiedKey
            })
            // remove excluding fuel and travel key value pair (unnecessary info for here)
            newHeaders.splice(6, 1)
            totalsRowArray.splice(6, 1)
            // calculate column widths for newHeaders
            const newHeadersColWidths = newHeaders.map(header =>
              Math.max(header.length, ...newHeaders.map(row => String(row[header]).length)),
            )
            // combine the calculated column widths
            const combinedColWidths = colWidths.map((width, index) => Math.max(width, newHeadersColWidths[index] || 0))
            // Set widths for the newHeaders, add +1 padding
            cbsWorksheet['!cols'] = combinedColWidths.map(width => ({ width: width + 1 }))

            // insert the totals title into the worksheet
            XLSX.utils.sheet_add_aoa(cbsWorksheet, [totalsTitle], { origin: newIndex })
            // insert the totals headers into the worksheet
            XLSX.utils.sheet_add_aoa(cbsWorksheet, [newHeaders], { origin: newIndex + 1 })
            // insert the new totals row into the worksheet
            XLSX.utils.sheet_add_aoa(cbsWorksheet, [totalsRowArray], { origin: newIndex + 2 })

            // add cbs excel sheet to workbook
            XLSX.utils.book_append_sheet(workbook, cbsWorksheet, 'Contractor Balance Sheet Report')
          } else {
            console.log('No data to export')
          }
        }
        // download depending on export type passed to component
        if (this.exportType === 'xlsx') {
          XLSX.writeFile(workbook, `CBS Report ${this.startDate} to ${this.endDate} (${reportInfoString}).xlsx`)
        } else if (this.exportType === 'csv') {
          // convert the worksheet data to a CSV string using papaparse and download it
          const data = papaParse.unparse(XLSX.utils.sheet_to_json(cbsWorksheet, { header: 1 }))
          const blob = new Blob([data], { type: 'text/csv' })
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.setAttribute('href', url)
          a.setAttribute('download', `CBS Report ${this.startDate} to ${this.endDate} (${reportInfoString}).csv`)
          a.click()
        } else {
          console.log('No export type provided')
        }
      } else {
        console.log('No data source provided')
      }
    },
    formatColumns() {
      // manage columns for excel sheet
      let formattedData = []

      if (this.dataSource === 'cbs') {
        formattedData = this.dataToExport.map(data => {
          return {
            Ticket_Num: data.ticketNum,
            Ticket_Date: data.ticketDate,
            Job: data.jobID,
            Operations: data.operations,
            Unit: data.unitID,
            Customer: data.customer,
            Product: data.product,
            Ticket_Hours: data.ticketHours,
            Hours: data.hours,
            Rate: data.rate,
            Subtotal_Before_FS: data.subtotalBeforeFS,
            Fuel_Surcharge_Percent: `${data.fuelSurcharge * 100}%`,
            Fuel_Surcharge: data.fuelSurchargeSubtotal,
            Subtotal: data.subtotal,
            Commission_Rate: data.commissionRate,
            Commission: data.discount,
            Subtotal_After_Commission: data.lineTotal,
            Gst: data.gst,
            Total: data.total,
            Contractor_Approved: data.contractorApproved,
            Contractor_Invoiced: data.contractorInvoiced,
          }
        })
      }

      return formattedData
    },
  },
}
</script>
